import React, {useRef} from "react";
import {AttachmentsForRenderProps, dsImageRotation, PageImageModel} from "types/attachment";
import ImageDefault from "./Image.default.function";
import AutoSizer from 'react-virtualized/dist/es/AutoSizer';
import List from 'react-virtualized/dist/es/List';
import InfiniteLoader from 'react-virtualized/dist/es/InfiniteLoader';
import Menu from "./Menu.function";
import _ from "lodash";
import {IMG_BASE, IMAGE_MIME_TYPE} from "utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "redux";
import * as reduxSelectors from "components/store/application.reducers";
import {cacheAttachmentsImagesUrls, changeZoomPercentage} from "components/taskdetails-remake/taskDetails.action";
import FetchAttachmentBlobFunction from "components/taskdetails-remake/panels/attachments/FetchAttachmentBlob.function";
import {launchDarkly} from "utils/launchDarkly";
import ImageTiff from "components/taskdetails-remake/panels/attachments/attachmentTypes/Images/Image.tiff.function";


const ROW_HEIGHT = 990;
const OVERSCAN_ROW_COUNT = 2;

const STATUS_LOADING = "STATUS_LOADING";
const STATUS_LOADED = "STATUS_LOADED";

const TIFF = "tiff";

const Image = ({collection}: AttachmentsForRenderProps): React.ReactElement => {
    const autoSizerContainer = useRef<HTMLDivElement>(null);
    const [dsImageRotation, setDsImageRotation] = React.useState<dsImageRotation>({1: 0});
    const [pageInView, setPageInView] = React.useState<number>(1);
    const [goToPage, setGoToPage] = React.useState<number | null>(null);
    const zoom = useSelector((store: Store) => reduxSelectors.getZoomPercentage(store));

    const userData = useSelector((store: Store) => reduxSelectors.getUsersData(store));
    const dsImages = useSelector((store: Store) => reduxSelectors.getImagesForRender(store));
    const [loadedRowsMap, setLoadedRowsMap] = React.useState<{}>({});
    const [loadingRowCount, setLoadingRowCount] = React.useState<number>(0);

    const dispatch = useDispatch();

    const byapssCIRTiffs: null | boolean = launchDarkly.userClient && launchDarkly.userClient.variation("bypass-cir-for-tiff", false);

    const loadAttachments = async (image: PageImageModel) => {
        const result = await FetchAttachmentBlobFunction(image.url, IMAGE_MIME_TYPE, userData);
        dispatch(cacheAttachmentsImagesUrls(collection.id, image.pageId, result));
    }

    const _rowRender = ({index, key, style}: any) => {
        if (!dsImages[collection.id] || dsImages[collection.id].length === 0) return (<div/>);
        const image = dsImages[collection.id][index] || {dsURL: ""};

        return collection.mimeType.includes(TIFF) && byapssCIRTiffs ?
            <ImageTiff src={image.dsURL} style={style} key={key} zoom={zoom} id={image.pageId}
                       rotation={dsImageRotation[image.pageId] ? dsImageRotation[image.pageId] : null}/> :
            <ImageDefault src={image.dsURL} style={style} key={key} zoom={zoom} id={image.pageId}
                          rotation={dsImageRotation[image.pageId] ? dsImageRotation[image.pageId] : null}/>
    }

    const _isRowLoaded = ({index}) => {
        return !!loadedRowsMap[index];
    }

    const _loadMoreRows = async ({startIndex, stopIndex}) => {
        const increment = stopIndex - startIndex + 1;
        const newLoadedRowsMap = _.cloneDeep(loadedRowsMap);

        for (let i = startIndex; i <= stopIndex; i++) {
            newLoadedRowsMap[i] = STATUS_LOADING;
            const image = dsImages[collection.id] && dsImages[collection.id].length > 0 ? dsImages[collection.id][i] : [];

            if (!image?.dsURL) {
                await loadAttachments(collection.images[i]);
                newLoadedRowsMap[i] = STATUS_LOADED;
                setLoadedRowsMap(newLoadedRowsMap);
            }
        }
        setLoadingRowCount(loadingRowCount + increment);
    }

    const rotateImage = () => {
        const newDsImageRotation = _.cloneDeep(dsImageRotation);
        const currentRotation = newDsImageRotation[pageInView] || 0;
        const newRotation = currentRotation > 2 ? 0 : currentRotation + 1;

        newDsImageRotation[pageInView] = newRotation;
        setDsImageRotation(newDsImageRotation);
    }

    const handleScroll = (event: any) => {
        const pageHeight = zoom ? (zoom * IMG_BASE.height) / 100 : ROW_HEIGHT;
        let currentPage = parseInt(String((event.scrollTop / pageHeight)), 10) + 1;
        setPageInView(currentPage);
    }

    const scrollToImage = (page: number) => {
        if (!collection.images || collection.images.length === 0) return;
        if (page > 0 && page <= collection.images.length + 1) {
            setGoToPage(page);
            setPageInView(page);
        }
    }

    //const height = autoSizerContainer.current ? autoSizerContainer.current?.clientHeight - 20 : ROW_HEIGHT;
    //const width = autoSizerContainer.current ? autoSizerContainer.current?.clientWidth : IMG_BASE.width + 20;

    return (
        <div className={"w-100 h-100 images"} ref={autoSizerContainer}>
            <Menu totalImages={collection?.images?.length} rotateImage={rotateImage} scrollToImage={scrollToImage}
                  pageInView={pageInView} zoomPercentage={zoom} changeZoom={changeZoomPercentage}/>
            {collection && collection.images && collection.images.length > 0 &&
                <InfiniteLoader
                    isRowLoaded={_isRowLoaded}
                    loadMoreRows={_loadMoreRows}
                    rowCount={collection.images.length}>
                    {({onRowsRendered, registerChild}) => (
                        <AutoSizer className="images-auto-sizer w-100 bg-natural-white py-2 rounded-8">
                            {({width, height}: any) => (
                                <List
                                    ref={registerChild}
                                    onRowsRendered={onRowsRendered}
                                    className="images-auto-sizer__list"
                                    height={height || 500}
                                    overscanRowCount={OVERSCAN_ROW_COUNT}
                                    rowCount={collection.images?.length || 0}
                                    rowHeight={(zoom * IMG_BASE.height) / 100}
                                    rowRenderer={_rowRender}
                                    id={"DS-Images-List" + collection.id}
                                    width={width || 500}
                                    scrollToAlignment="start"
                                    onScroll={handleScroll}
                                    scrollToIndex={goToPage ? goToPage : undefined}
                                />
                            )}
                        </AutoSizer>
                    )}
                </InfiniteLoader>
            }
        </div>);
}

export default Image;